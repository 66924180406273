/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutAddressPopup {
    &-Content {
        padding: 56px;
        min-width: 836px;
        max-width: 836px;

        @include mobile {
            min-width: unset;
            max-width: unset;
            position: fixed;
            height: 444px;
            inset-block-end: 0;
            padding: 40px 20px;
            width: 100%;
            z-index: 1000;
        }
    }
}

.MyAccountAddressPopup .Popup-Heading {
    font-family: $font-Montserrat;
}

button.Button.Button_isHollow.CheckoutAddressForm-Button {
    height: 40px;
}

.CheckoutAddressForm {
    &-ShipButton {
        display: flex;

        @include desktop {
            justify-content: flex-end;
        }

        @include mobile {
            width: 100%;
            margin-block-end: 16px;
        }
    }

    &-Button {
        text-transform: unset;

        @include desktop {
            max-width: 196px;
            width: 100%;
            height: 40px;
        }

        @include mobile {
            width: 100%;
            height: 36px;
            background: var(--primary-base-color);
            border: 1px solid #E43D2F;
            border-radius: 2px;
            color: #FFFFFF;
        }
    }

    .FieldGroup-Wrapper.FieldGroup-Wrapper_defaultaddress {
        .FieldGroup {
            .Field-Wrapper {
                &:nth-child(-n+2) {
                    display: none;
                }
            }
        }
    }
}

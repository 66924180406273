/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkout-max-width: 1100px;
    --checkoutHeaderHeight: 123px;
    --checkoutMobileHeaderHeight: calc(var(--offline-notice-height) + 76px);
}

.Checkout {
    background: #FAFAFA;

    @include desktop {
        margin-block-start: 0;
    }

    @include mobile {
        margin-block-start: 0;
        padding-block-start: 0;
    }

    &::before {
        display: none;
    }


    &-orderSuccess {
        display: none;
    }

    &-Wrapper {
        display: flex;
        grid-template-columns: unset;

        @include desktop {
            grid-gap: 30px;
            border-top: 1px solid #F1F1F1;
        }

        @include mobile {
            flex-direction: column-reverse;
        }
    }

    &-CheckoutStepBlock {
        @include desktop {
            width: 67%;
        }
    }

    &-CheckoutSummaryFloatBlock {
        @include desktop {
            width: 30%;
        }
    }

    .CheckoutShipping-Button {
        display: none;
    }

    .Fee {
        display: none;
    }

    &-CheckoutHeading {
        h2 {
            margin: 28px 0 16px;
            font-size: 24px;
            font-family: $font-Montserrat;

            @include mobile {
                font-size: 16px;
                margin: 38px 0 8px;
            }
        }

        p {
            color: #707070;

            @include mobile {
                font-size: 12px;
                margin-block-end: 20px;
            }
        }
    }

    &-Heading {
        font-size: 16px;
        margin: 0;

        @include mobile {
            font-size: 14px;
        }
    }

    .CheckoutAddressBook {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 12px #0000000d;
        padding: 24px;
        margin-block-end: 24px;

        @include mobile {
            padding: 16px;
            margin-block-end: 16px;
        }

        &-Wrapper {
            @include desktop {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 21px;
            }
        }

        &-Button {
            text-transform: none;
            height: 40px;
            border: 1px solid #E53D2F;
            border-radius: 3px;

            @include mobile {
                max-width: 140px;
                width: 100%;
                height: 30px;
                font-size: 12px;
                padding: 0 10px;
            }

            .AddIcon {
                fill: var(--primary-base-color);
                width: 15px;
                height: 15px;
                border: 1px solid var(--primary-base-color);
                border-radius: 20px;
                margin-inline-end: 14px;

                @include mobile {
                    width: 13px;
                    height: 11px;
                    margin-inline-end: 6px;
                }
            }
        }

        &-HeadingBlock {
            @include mobile {
                align-items: center;
            }
        }
    }

    &-DescText {
        @include mobile {
            color: #707070;
            font-size: 12px;
            margin-block-end: 12px;
        }
    }

    button.CheckoutAddressBook-Button.Button.Button_isHollow:hover {
        height: 40px;
        background: var(--primary-base-color);
        color: #FFFFFF;
        border-radius: 3px;
        border: 1px solid var(--primary-base-color);

        @include mobile {
            height: 30px;
            font-size: 12px;
        }

        .AddIcon {
            fill: #FFFFFF;
            border: 1px solid #FFFFFF;
        }
    }
    
    .CheckoutAddressTable {
        &-CheckRadio {
            width: 19px;
            height: 24px;
            fill: #FFFFFF;

            @include mobile {
                width: 16px;
                height: 20px;
            }

            &_isSelected {
                background: var(--primary-base-color);
            }
        }

        &-CheckRadioBlock {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            background: #CBCBCB;

            @include mobile {
                width: 20px;
                height: 20px;
            }

            &_isSelected {
                background: var(--primary-base-color);
            }
        }

        &-Button {
            border: 1px solid #DDDDDD;

            @include desktop {
                max-width: 269px;
                width: 100%;
                height: 100%;
            }

            &_isSelected {
                border: 1px solid var(--primary-base-color);
            }

            .KeyValueTable {
                width: 100%;
                height: 100%;

                @include mobile {
                    font-size: 12px;
                }

                &-Wrapper {
                    height: 100%;

                    @include mobile {
                        overflow-x: unset;
                    }
                }

                &-Heading {
                    display: flex;
                    flex-direction: row-reverse;
                    padding: 0;
                    height: 100%;
                    align-items: flex-end;

                    .RadioButtonIcon {
                        visibility: hidden;
                    }
                }

                tr:not(:last-child) {
                    border-bottom: 0;
                }

                td {
                    padding: 0;
                    word-break: break-word;
                }

                tr:first-child td {
                    @include desktop {
                        padding: 20px 20px 0;
                    }

                    @include mobile {
                        padding: 12px 12px 0;
                    }
                }

                tr:not(:first-child) td {
                    @include desktop {
                        padding: 2px 20px 0;
                    }

                    @include mobile {
                        padding: 0 12px;
                    }
                }
            }
        }
    }

    .CheckoutDeliveryOptions {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 12px #0000000d;
        padding: 24px;

        @include mobile {
            padding: 16px;
            margin-block-end: 16px;
        }

        .CheckoutDeliveryOption {
            border-top: unset;

            @include desktop {
                width: 31%;
                display: flex;
                align-items: center;
                min-height: calc(100% - 40px);
            }

            background: #FAFAFA 0% 0% no-repeat padding-box;
            box-shadow: inset 1px 1px 6px #00000008;
            border-radius: 4px;
            padding: 0;

            @include mobile {
                padding: 16px;
            }

            @include mobile {
                width: 100%;
            }

            &_isDisabled {
                margin-block-start: 0;
            }

            &_isActive {
                border: 1px solid #E53D2F;
                background: #FFFFFF;
                box-shadow: inset 1px 1px 6px #00000008;
            }

            &-Row {
                @include mobile {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }
            }

            &-Span {
                @include desktop {
                    margin-block-start: 10px;
                }

                .method-title {
                    font-weight: normal;
                    display: flex;
                    column-gap: 5px;
                    flex-wrap: wrap;

                    @include mobile {
                        font-size: 12px;
                    }
                }

                &_isDisabled {
                    margin-block-start: 0;
                }
            }

            &-Button {
                align-items: center;
                height: 100%;
                padding: 20px;
            }

            &_isHoverExcluded {
                &:hover {
                    .CheckoutDeliveryOption-Message {
                        @include desktop {
                            display: block;
                        }
                    }
                }
            }

            &-Message {
                @include desktop {
                    font-size: 12px;
                    width: 150px;
                    margin-block-start: 10px;
                }

                @include mobile {
                    font-size: 11px;
                    width: 50%;
                }
            }

            .Field-Wrapper_type_radio {
                width: 19%;
            }

            .input-control {
                @include desktop {
                    margin-inline-end: 9px;
                    border-radius: 10px;
                }
            }

            .RadioButtonIcon {
                @include desktop {
                    margin-inline-end: 16px;
                }

                @include mobile {
                    margin-inline-end: 10px;
                }
            }
        }

        .Checkout-ExtraText {
            margin-block-start: 10px;
            padding: 8px 16px;
            font-size: 14px;
            background: #FFF5F4;

            @include mobile {
                display: none;
            }
        }

        .Checkout-Heading {
            padding-block-end: 10px;
            border-bottom: 1px solid #F2F2F2;
            margin-block-end: 16px;

            @include mobile {
                padding-block-end: 12px;
                margin-block-end: 16px;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            @include desktop {
                grid-gap: 17px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include mobile {
                grid-gap: 12px;
                flex-direction: column;
            }
        }

        .CheckoutDeliveryOption:hover:not(.CheckoutDeliveryOption_isHoverExcluded) .input-control::after {
            --checkmark-color: #E53D2F;
        }
    }

    .CheckoutBilling {
        margin-block-start: 0;
        border-bottom: 1px solid #F2F2F2;
        margin-block-end: 20px;

        &-AlertSection {
            p {
                padding: 8px 16px;
                font-size: 14px;
                background: #FFF5F4;
            }
        }
    }

    .CheckoutPayments {
        margin-block-end: 20px;

        &-Methods {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 17px;

            @include mobile {
                grid-gap: 12px;
                grid-template-columns: unset;
                flex-direction: column;
            }
        }

        .Checkout-Heading {
            padding-block-end: 10px;
            border-bottom: 1px solid #F2F2F2;
            margin-block-end: 16px;

            @include mobile {
                padding-block-end: 12px;
                margin-block-end: 16px;
            }
        }

        .CheckoutPayment {
            border-top: unset;
            padding: 0;

            @include desktop {
                width: 23%;
                height: 100%;
            }

            @include mobile {
                width: 100%;
            }

            &-Button {
                @include desktop {
                    max-width: 196px;
                    max-height: 84px;
                    width: 100%;
                    height: 100%;
                }

                @include mobile {
                    width: 100%;
                    justify-content: flex-start;
                    padding: 16px;
                }

                background: #FAFAFA 0% 0% no-repeat padding-box;
                box-shadow: inset 1px 1px 6px #00000008;
                border-radius: 4px;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #FAFAFA;

                &_isSelected {
                    border: 1px solid var(--primary-base-color);
                    background: #FFFFFF;
                    box-shadow: inset 1px 1px 6px #00000008;
                }

                .Field-Wrapper.Field-Wrapper_type_checkbox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }
            }

            .input-control {
                visibility: hidden;
                width: 0;
                margin: 0;
            }
        }
    }

    &-BillingWrapper {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 12px #0000000d;
        padding: 24px;

        @include mobile {
            padding: 16px;
            margin-block-end: 16px;
        }
    }

    &-StoreCredit {
        background: #FFF5F4;
        padding: 20px;

        @include mobile {
            padding: 16px;
        }

        .Field {
            margin-block-start: 20px;

            @include mobile {
                margin-block-start: 16px;
            }
        }
    }

    &-StoreCreditLabel {
        font-size: 16px;
        font-weight: bold;
        margin-block-end: 20px;

        @include mobile {
            font-size: 14px;
            margin-block-end: 8px;
        }
    }

    &-StoreCreditData {
        font-size: 14px;
        color: #E53D2F;

        @include mobile {
            font-size: 12px;
        }
    }

    &-StoreCreditInput {
        font-size: 14px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-CheckoutSummaryBlock {
        @include desktop {
            position: fixed;
            inset-inline-end: 0;
            height: 100%;
            width: 32%;
            inset-block-start: var(--checkoutHeaderHeight);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 11px 8px #0000001a;
            overflow-y: auto;
        }
    }

    .CheckoutOrderSummary {
        padding: 81px 60px 24px 24px;

        @include mobile {
            padding: 16px;
        }

        &-Header {
            @include mobile {
                display: block;
            }

            h2 {
                display: block;
                font-size: 20px;
                padding-block-end: 16px;
                border-bottom: 1px solid #F2F2F2;
                margin-block-end: 36px;

                @include desktop {
                    font-family: $font-Montserrat;
                }

                @include mobile {
                    padding-block-end: 12px;
                    margin-block-end: 16px;
                    font-size: 14px;
                }
            }
        }

        &-Text {
            @include desktop {
                color: #0F0F0F;
            }
        }

        &-SummaryItem_isTotal {
            padding: 36px 0;

            @include mobile {
                padding: 16px 0 24px;
            }
        }

        &-PlaceOrder {
            display: none;
            width: 100%;
            text-transform: unset;
            height: 40px;
            border-radius: 4px;
        }

        &-ButtonWrapper {
            @include mobile {
                display: block;
            }
        }
    }

    button.Button.CheckoutOrderSummary-PlaceOrder:not([disabled]):hover {
        height: 40px;
        border-radius: 4px;
    }

    .CartAdditionalFees-Option {
        @include desktop {
            font-size: 16px;
            margin-block-end: 24px;
        }

        @include mobile {
            margin-block-end: 16px;
        }
        
        &-Label {
            color: #707070;

            @include desktop {
                letter-spacing: 0px;
                font-size: 16px;
                color: #0F0F0F;
            }
        }

        span {
            font-size: 16px;

            @include mobile {
                font-size: 14px;
            }
        }
    }

    .hideOnScroll .Checkout-StickyButtonWrapper {
        @include mobile {
            transform: unset;
        }
    }

    &-StickyButtonWrapper {
        @include mobile {
            inset-block-end: 0;
            transform: unset;
            padding: 18px 20px;
            box-shadow: 0px -3px 12px #00000017;
            border-top: unset;

            .hideOnScroll & {
                transform: unset;
            }
        }

        .Checkout-OrderTotal {
            @include mobile {
                display: none;
            }
        }

        .Button {
            @include mobile {
                text-transform: unset;
                font-size: 14px;
                border-radius: 2px;
            }
        }
    }

    button#CheckoutPlaceOrder {
        @include desktop {
            display: none;
        }
    }

    &-SecurePayment {
        display: none;

        @include mobile {
            padding: 0;
            grid-gap: 10px;
            align-items: center;
        }

        &-Label {
            @include mobile {
                font-size: 12px;
                letter-spacing: 0px;
                color: #707070;
                line-height: 20px;
            }
        }
    }

    &-orderSuccessWrapper {
        .Checkout {
            &-CheckoutStepBlock {
                width: 100%;
            }
            
            &-CheckoutHeading {
                display: none;
            }
        }
    }

    &-OrderSuccessPage {
        @include mobile {
            padding-block-end: 0;
            margin-block-end: 0;
        }

        .Footer {
            @include mobile {
                background-color: #FAFAFA;
                padding-block-start: 50px;
            }

            &-Content {
                @include mobile {
                    background-color: unset;
                }
            }
    
            &-Top {
                @include mobile {
                    border-top: 4px solid #F7F7F7;
                    margin: 0 24px;
                }
            }
        }
    }

    &-CustomerNotesField {
        margin-block-end: 2rem;

        .Field-Wrapper {
            .Field_type_textarea {
                .Field-LabelContainer {
                    label {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                textarea {
                    width: 100%;
                    background: #FAFAFA;
                    box-shadow: inset 1px 1px 6px #00000014;
                }
            }
        }
    }
}

button.Button.CheckoutOrderSummary-PlaceOrder {
    display: none;
}

body.checkout-page button.Button.CheckoutOrderSummary-PlaceOrder {
    @include desktop {
        display: block;
    }
}

body.checkout-page .Checkout-SecurePayment {
    @include mobile {
        display: flex;
    }
}

body.checkout-page footer.Footer {
    display: none;
}

body.checkout-page .Header-Nav {
    @include desktop {
        height: 121px;
    }
}

body.checkout-page .Router-MainItems {
    @include desktop {
        min-height: calc(100vh - var(--checkoutHeaderHeight));
    }
}

.checkout-page .Breadcrumbs {
    background: #FAFAFA;
}

.checkout-page .Breadcrumbs-List {
    @include mobile {
        padding: 10px 0 4px 0;
        border-bottom: 1px solid #F1F1F1;
    }
}

.checkout-page {
    .Header-Wrapper {
        .OfflineNotice {
            inset-block-start: 189px;

            @include mobile {
                inset-block-start: 76px;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-payment-check-mark-color: var(--primary-base-color);
    --checkout-payment-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-payment-border-color: var(--primary-divider-color);
}

.CheckoutPayment {
    padding-block: 19px;
    padding-inline-start: 0;
    margin-block-end: 0;
    border-block-start: 1px solid var(--checkout-payment-border-color);

    &-CheckoutPaymentParent {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include tablet {
        width: 100%;
    }

    &::before {
        content: none;
    }

    &:first-child {
        border-block-start: 0;
    }

    &:last-child {
        margin-block-end: 0;
        min-width: 200px;
    }

    &-Button {
        --check-mark-opacity: 0;

        width: 100%;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        cursor: pointer;

        .Field {
            margin-block-start: 0px;
        }
    }

    .Field_type_checkbox {
        margin-block-start: 0;
    }  

    &-paymentInfo {
        margin-block-start: 20px;
        padding: 8px 16px;
        font-size: 14px;
        background: #FFF5F4;
        
        h4 {
            font-weight: 600;
        }

        p {
            margin-block: 6px 0;
            margin-inline-start: 10px;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutOrderSummary {
    border: none;
    background: #FFFFFF;
    box-shadow: 1px 1px 13px #00000005;
    padding: 20px;

    @include mobile {
        padding: 16px;
    }

    &-Header {
        h2 {
            display: none;
        }
    }

    &-OrderTotals {
        ul {
            display: flex;
            flex-direction: column;
        }
    }
    
    &-SummaryItem {
        padding: 0;

        @include desktop {
            margin-block-end: 24px;
        }

        @include mobile {
            margin-block-end: 16px;
        }

        &_isTotal {
            padding: 24px 0;
            border-top: 1px solid #F1F1F1;

            @include mobile {
                padding: 16px 0 0 0;
            }

            .CheckoutOrderSummary-Text {
                letter-spacing: 0px;
                color: #0F0F0F;
                font-size: 16px;
                font-weight: bold;

                @include mobile {
                    font-size: 14px;
                }
            }

            .CheckoutOrderSummary-Price strong {
                font-weight: bold;
            }
        }
    }

    &-Text {
        letter-spacing: 0px;
        color: #707070;
        font-size: 16px;

        @include mobile {
            font-size: 14px;
        }
    }

    &-Price {
        letter-spacing: 0px;
        color: #0F0F0F;
        font-size: 16px;

        @include mobile {
            font-size: 14px;
        }

        strong {
            font-weight: normal;
        }
    }

    &-ButtonWrapper {
        @include mobile {
            position: unset;
            height: unset;
            transition: unset;
            z-index: unset;
            transform: none;
            padding: 0;

            .hideOnScroll & {
                transform: unset;
            }
        }
    }

    .CartPage-CheckoutButtonWrapper {
        @include mobile {
            display: flex;
        }
    }
}

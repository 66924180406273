/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --secondary-base-color: #f4f4f4;
    --menu-total-height: 66px;
    --checkout-max-width: 1100px;
    --checkoutHeaderHeight: calc(var(--header-top-menu-height) + 121px);
    --checkoutMobileHeaderHeight: calc(var(--offline-notice-height) + 76px);
}

.Checkout {
    margin-block-start: 0;
    background: var(--secondary-base-color);

    @include mobile {
        padding-block: 1px 60px;
    }
}

.CheckoutSuccess {
    display: grid;
    background-color: #fff;
    margin-block-end: 2rem;
    align-items: center;

    @include desktop {
        grid-template-columns: 5fr 4fr;
    }
    
    @include mobile {
        margin-block-start: 28px;
    }

    &-ContinueButton {
        background-color: #BB8376;
        border-color: #BB8376;
        color: #fff;
        width: 220px;
        border-radius: 5px;

        @include mobile {
            width: 100%;
        }

        @include desktop {
            margin-block-start: 24px;
        }
    }

    &-ButtonWrapper {
        display: flex;
        z-index: 1;
        background-color: #fff;

        @include mobile {
            padding: 14px;
            position: relative;
            inset-inline-start: 0;
            width: 100%;
            inset-block-end: 0;
            gap: 12px;
        }
    }

    .Button {
        @include mobile {
            width: 100%;
            text-align: center;
            text-transform: capitalize;
        }
    }

    .CheckoutRegistrationLink {
        margin-block-start: 20px;
    }

    &-orderID {
        display: flex;
        align-items: center;

        @include mobile {
            margin-block-end: 10px;
        }

        label {
            font-size: 16px;
        }

        span {
            font-size: 16px;
            margin-inline-start: 2rem;
            color: var(--primary-base-color);
            font-weight: 700;
        }
    }

    &-content {
        padding: 0 3rem;
        
        @include mobile {
            padding: 2rem;
            order: 2;
        }

        .OrderSuccessTitle {
            h1 {
                font-size: 24px;
                margin-block-end: 2rem;

                @include mobile {
                    text-align: center;
                }
            }
        }

        p {
            margin-block-end: 3rem;

            @include mobile {
                margin-block-end: 2rem;
            }
        }
    }

    &-img {
        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 1;
            padding: 10px;
        }
    }

    &-OrderedSummary {
        @include desktop {
            display: grid;
            gap: 2rem;
            grid-template-columns: 5fr 2fr;
        }

        @include mobile {
            .OrderedAddress-Details {
                grid-column: 1;
                grid-row: 1;
            }
        }
    }

    &-Summary {
        // @include mobile {
        //     grid-column: 1;
        //     grid-row: 2;
        //     display: grid;
        // }

        .summaryBlock {
            padding: 3rem;
            background-color: #fff;

            @include mobile {
                margin-block-end: 1rem;
            }

            h4 {
                font-size: 16px;
                color: var(--primary-base-color);
                font-weight: bold;
                margin-block-end: 3rem;
                padding-block-end: 1rem;
                border-bottom: 1px solid #F2F2F2;
            }

            .summaryList {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-block-end: 2rem;

                label {
                    font-size: 16px;
                }

                span {
                    font-size: 16px;
                }
            }

            .orderTotalPrice {
                padding-block-start: 2rem;
                border-block-start: 1px solid #0F0F0F;
                font-weight: bold;
            }
        }
    }
}

button.Button.CheckoutOrderSummary-PlaceOrder {
    display: none;
}

body.checkout-success-page button.Button.CheckoutOrderSummary-PlaceOrder {
    @include desktop {
        display: block;
    }
}

body.checkout-success-page .Checkout-SecurePayment {
    @include mobile {
        display: flex;
    }
}

body.checkout-success-page footer.Footer {
    display: none;
}

body.checkout-success-page .Header-Nav {
    @include desktop {
        height: 121px;
    }
}

body.checkout-success-page .Router-MainItems {
    @include desktop {
        min-height: calc(100vh - var(--checkoutHeaderHeight));
    }
}

body.checkout-success-page .Header-Wrapper {
    @include desktop {
        margin-block-end: var(--checkoutHeaderHeight);
    }
    
    @include mobile {
        margin-block-end: var(--checkoutMobileHeaderHeight);
    }
}

.checkout-success-page .Breadcrumbs {
    background: #FAFAFA;
}

.checkout-success-page .Breadcrumbs-List {
    @include mobile {
        padding: 10px 0 4px 0;
        border-bottom: 1px solid #F1F1F1;
    }
}

.checkout-success-page {
    .Header-Wrapper {
        .OfflineNotice {
            inset-block-start: 189px;

            @include mobile {
                inset-block-start: 76px;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutPayments {
    margin-block-end: 24px;

    @include mobile {
        margin-block-end: 28px;
    }

    @include wide-desktop {
        &-Methods {
            width: 50%;
        }
    }

    &-paymentInfo {
        margin-block-start: 20px;
        padding: 8px 16px;
        font-size: 14px;
        background: #FFF5F4;

        h4 {
            font-weight: 600;
        }

        p {
            margin-block: 6px 0;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutAddressBook {
    &-HeadingBlock {
        display: flex;
        justify-content: space-between;
        padding-block-end: 20px;
        border-bottom: 1px solid #F2F2F2;
        margin-block-end: 20px;

        @include mobile {
            padding-block-end: 12px;
            margin-block-end: 16px;
        }
    }

    &-ErrorMsg {
        font-size: 14px;
        background: #fff5f4;
        padding: 10px 14px;
        color: #e73b25;
        font-weight: 500;
        width: fit-content;
        margin-block: 2rem;
    }
}

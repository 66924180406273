/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutArchFrom {
    &-CheckoutArchContainer {
        padding: 0px 10px 18px 10px;
        max-width: 500px;

        input {
            width: 100%;
            z-index: 1;
            background: #FAFAFA;
            padding: 10px;
            border-radius: 8px;
            box-shadow: inset 1px 1px 6px rgba(0,0,0,.0784313725490196);
        }

        .Field-LabelContainer {
            position: relative;
            inset-block-end: none;  
        }

        input::placeholder {
            opacity: 1;
            color: #7F7E7D;
        }
        
        .Field {
            margin-block-start: 18px;

            &-ErrorMessages {
                padding-block-start: 0px;
            }
        }

        .Field_type_text.Field_isValid input {
            // border: 2px solid #7F7E7D;
        }

        .Field_hasError input:not([type="number"]) {
            border: 1px solid #FAFAFA;
            border-bottom: 1px solid var(--primary-error-color);
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutCreditCardForm {
    margin-block-start: 2rem;
    max-width: 500px;
    width: 100%;

    h4 {
        text-align: start;
        font-weight: 700;
        font-size: 16px;
    }

    input {
        width: 100%;
        z-index: 1;
        background: #FAFAFA;
        box-shadow: inset 1px 1px 6px #00000014;
    }

    .Field-Label {
        padding-block-end: 5px;
        font-size: 14px;
        color: var(--field-label-color);
    }

    &-cardNumberField {
        span {
            position: absolute;
            inset-inline-end: 11px;
            inset-block-end: 0;
            z-index: 9;

            svg {
                width: 40px;
                height: 40px;
            }
        }
    }

    &-nameHolder,
    &-cardNumberField {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-block-start: 10px;
    }

    &-cvcField,
    &-dateField {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    &-dateAndCvc {
        display: flex;
        align-items: flex-start;
        column-gap: 1rem;
        justify-content: space-between;
        margin-block-start: 12px;

        > div {
            width: 100%;
        }
    }

    &-errorMsg {
        font-size: 12px;
        color: var(--primary-error-color);
        text-align: start;
    }

    .Field_error {
        input {
            border-bottom: 1px solid var(--primary-error-color);
        }
    }
}
